import React, { useEffect, useState } from "react";
import {
  Input,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@material-ui/core";
import { makeStyles, Chip } from "@material-ui/core";
import { useField } from "react-final-form";

const useStyles = makeStyles({
  chip: {
    marginLeft: "10px",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
  },
});
const defaultColors = [
  { title: "Negro", value: "#000000" ,name:"black" },
  { title: "Blanco", value: "#FFFFFF",name:"white" },
];

const ColorPicker = ({ source,label, colors = defaultColors , className, defaultColor = null}) => {
  const classes = useStyles();
  const { input: color_field } = useField(source);
  const [selected, setSelected] = useState();

  useEffect(()=>{
      if (color_field?.value){
        setSelected( color_field.value);
      }else{
        if (defaultColor){
          setSelected(defaultColor);
          color_field.onChange(defaultColor);
        }else{
          setSelected(null);
        }
      }
  },[])


  useEffect(()=>{
    if (selected){
      console.log("change");
      color_field.onChange(selected);
    }
  },[selected])

  if (selected === undefined) return null;

  return (
    <Box sx={{ width: { xs: "160px", md: "200px",} }} className={className}>
      <FormControl fullWidth>
        <InputLabel >
          {label}
        </InputLabel>
        <Select
          input={<Input />}
          variant="filled"
          onChange={(e) => {
            setSelected(
              colors.find((item) => item.name == e.target.value)?.name
            );
          }}
          style={{ minWidth: "200px",marginBottom: "28px" }}
          value={selected}
          renderValue={() => {
            const sel = colors.find((i) => i.name == selected);
           return( <div className={classes.chips}>
              <Chip
                variant="outlined"
                label={sel?.title || "Default"}
                icon={
                  <span
                    className={classes.chip}
                    style={{
                      background: sel?.value
                    }}
                  ></span>
                }
              />
            </div>)
          }}
        >
          <MenuItem value={null}>Default</MenuItem>
          {colors.map((color, index) => (
            <MenuItem key={index} value={color.name}>
              {color.title}{" "}
              <span
                className={classes.chip}
                style={{ background: color.value }}
              ></span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ColorPicker;
