import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  maxLength,
  useQueryWithStore,
  Loading,
  AutocompleteInput,
  NumberInput,
  DateInput,
  AutocompleteArrayInput
} from "react-admin";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { useFormState, useForm,useField } from "react-final-form";
import ImagePickerBenefits from "../components/ImagePickerBenefit";
import { ImageInputBenefit } from "../components/ImageInputBenefit";
import { TypeBenefit } from "../components/TypeBenefit";
import { useEffect, useState } from "react";
import RenderFields from "../components/RenderFields";

const Locations = ({ choices, source, label }) => {
  const { values } = useFormState();
  const [options, setOptions] = useState();
  const [current, setCurrent] = useState();
  const record = useRecordContext();
  const { change } = useForm();

  useEffect(() => {
    if (record && record?.locations && choices) {
      setCurrent(record?.business_id);
      setOptions(choices?.find(i => i.id == record.business_id)?.locations);
      const locids = record?.locations?.map(i => i.id);
      change('locations', locids);
    }
  }, [record, choices])

  useEffect(() => {
    if (values?.business_id && choices) {
      if (!current || values?.business_id != current) {
        setCurrent(values.busisnes_id);
        setOptions(
          choices?.find(i => i.id == values.business_id)?.locations
        );
      }
    }
  }, [values?.business_id, choices])

  if (!options) return null;

  return (
    <AutocompleteArrayInput
      choices={options}
      source={source}
      label={label}
      optionText="address"
      optionValue="id"
    />
  )
}


export const Form = () => {
  const { values } = useFormState();
  const record = useRecordContext();
  const { input: input_categories } = useField('categories');

  useEffect(() => {
    if (record && record?.categories) {
      const categories = record?.categories?.map(e => (e.id));
      input_categories.onChange(categories)
    }
  }, [record])

  const { data: categories } = useQueryWithStore({
    type: 'getList',
    resource: 'benefit_categories',
    payload: {
      filter: {
      },
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  const { data: provinces } = useQueryWithStore({
    type: 'getList',
    resource: 'provinces',
    payload: {
      filter: {
      },
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  const { data: business } = useQueryWithStore({
    type: 'getList',
    resource: 'business',
    payload: {
      filter: {
      },
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  const getOptions = (value, options) => {
    return options?.find(i => i.name == value)?.cities;
  }

  return (
    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TypeBenefit />
        <TextInput
          source="name"
          label="resources.benefits.fields.name"
          validate={[required()]}
        />
        {categories ? <AutocompleteArrayInput
          source="categories"
          label="resources.benefits.fields.categories"
          choices={categories}
          validate={[required()]}
          multiple
        /> : null}
        <TextInput
          source="short_description"
          label="resources.benefits.fields.short_description"
          validate={[required(), maxLength(50)]}
          multiline
          inputProps={{ maxLength: 50 }}
        />
        <TextInput
          source="description"
          label="resources.benefits.fields.description"
          validate={[required()]}
          style={{ width: "450px" }}
          multiline
        />
        {business ? <AutocompleteInput
          source="business_id"
          label="resources.benefits.fields.business"
          choices={business}
          validate={[required()]}
        ></AutocompleteInput> : <Loading />}

        <Locations choices={business} source="locations" label="Locales" />

        {provinces ? <AutocompleteInput
          source="province"
          label="resources.benefits.fields.province"
          choices={provinces}
          validate={[required()]}
          optionText="name"
          optionValue="name"

        ></AutocompleteInput> : <Loading />}
        {values?.province ? <AutocompleteInput
          source="city"
          label="resources.benefits.fields.city"
          choices={getOptions(values.province, provinces)}
          validate={[required()]}
          optionText="name"
          optionValue="name"
        /> : null}
        <NumberInput source="amount" label="resources.benefits.fields.amount" />
        <DateInput source="date_start" label="resources.benefits.fields.date_start" />
        <DateInput source="date_end" label="resources.benefits.fields.date_end" />
        <ImageInputBenefit />
        <ImageInputBenefit source="gallery" multiple={true} label="Galeria" />
        <ImagePickerBenefits />
        <RenderFields source="assets" label="Campos extra" />
      </div>
    </div>
  );
};

export const BenefitsCreate = ({ ...props }) => {
  const transform = (values) => {
    let copy = { ...values };
    copy.company = apiCOD;
    return copy;
  };
  return (
    <Create title=" " transform={transform} redirect="list" {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Create>
  );
};
