import medioData from "Static/data/medio.json";

const style = `
amp-story {
  font-family: "Oswald", sans-serif;
  color: #fff;
}
amp-story-page {
  background-color: #000;
}
h1 {
  font-weight: bold;
  font-size: 2.875em;
  font-weight: normal;
  line-height: 1.174;
}
p {
  font-weight: normal;
  font-size: 1.3em;
  line-height: 1.5em;
  color: #fff;
}
q {
  font-weight: 300;
  font-size: 1.1em;
}
amp-story-grid-layer.bottom {
  align-content: end;
}
amp-story-grid-layer.noedge {
  padding: 0px;
}
amp-story-grid-layer.center-text {
  align-content: center;
}
.wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
}
.banner-text {
  text-align: center;
  background-color: #000;
  line-height: 2em;
}
a.amp-link{
  font-size: 20px;
  font-weight: 500;
  margin: 16px auto 16px auto;
  color:black;
  background:#bababa;
  padding: 4px 8px;
  max-height: 48px;
  box-sizing:border-box;
  text-decoration: none;
  border-radius: 25px;
  display:flex;
  justify-content:center;
  align-items:center;
  flex:1;
  width: fit-content;
  min-width: 150px;
  opacity:0.9;
}
a.amp-link:hover{
  color: black;
  cursor:pointer;
  opacity:1;
}
/** colors */
h1.black{
  color: #000000;
}
h1.white{
  color: #FFFFFF;
}
p.white{
  color: #FFFFFF;
}
p.black{
  color: #000000;
}
/*** sizes */
h1.small{
  font-size: 24px;
}
h1.medium{
  font-size: 32px;
}
h1.large{
  font-size: 40px;
}
h1.extral{
  font-size: 48px;
}
p.small{
  font-size: 24px;
}
p.medium{
  font-size: 32px;
}
p.large{
  font-size: 40px;
}
p.extral{
  font-size: 48px;
}
`;
export default function PreviewStory({ story }) {
  const schema = {
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${process.env.REACT_APP_CLIENT_HOST}/stories/${story.slug}`,
    },
    headline: `${story.title}`,
    image: {
      "@type": "ImageObject",
      url: `${story.image ? story.image : story.logo}`,
      height: 800,
      width: 800,
    },
    datePublished: `${story.created_at}`,
    dateModified: `${story.updated_at}`,
    author: {
      "@type": "Media",
      name: `${medioData.nombre}`,
    },
    publisher: {
      "@type": "Organization",
      name: `${medioData.nombre}`,
      logo: {
        "@type": "ImageObject",
        url: `${story.logo}`,
        width: 600,
        height: 60,
      },
    },
    description: `${story.description}`,
  };
  return (
    <html>
      <head>
        <meta
          name="viewport"
          content="width=device-width,minimum-scale=1,initial-scale=1"
        />
        <style amp-boilerplate="">
          {`
            body {
              -webkit-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              -moz-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              -ms-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              animation: -amp-start 8s steps(1, end) 0s 1 normal both;
            }
            @-webkit-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-moz-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-ms-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-o-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
          `}{" "}
        </style>
        <noscript>
          <style amp-boilerplate="">{`
          body {
            -webkit-animation: none;
            -moz-animation: none;
            -ms-animation: none;
            animation: none;
          }
          `}</style>
        </noscript>
        <script async src="https://cdn.ampproject.org/v0.js"></script>
        <script
          async
          custom-element="amp-video"
          src="https://cdn.ampproject.org/v0/amp-video-0.1.js"
        ></script>
        <script
          async
          custom-element="amp-story"
          src="https://cdn.ampproject.org/v0/amp-story-1.0.js"
        ></script>
        <link
          href="https://fonts.googleapis.com/css?family=Oswald:200,300,400"
          rel="stylesheet"
        />
        <title>{story.title}</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_CLIENT_HOST}/stories/${story.slug}`}
        />
        {style && (
          <style amp-custom="" dangerouslySetInnerHTML={{ __html: style }} />
        )}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        ></script>
      </head>
      <body>
        <amp-story
          standalone=""
          title={story.title}
          publisher={medioData.nombre}
          publisher-logo-src={story.logo}
          poster-portrait-src={story?.image || null}
        >
          {story.google_slides.map((item, key) => {
            const settings = item?.settings;
            return (
              <amp-story-page id={"page" + item.order} key={key}>
                <amp-story-grid-layer template="fill">
                  {item.type == "image" ? (
                    <amp-img
                      alt={item.description}
                      src={item.source}
                      width="720"
                      height="1280"
                      layout="responsive"
                    ></amp-img>
                  ) : item.type == "video" ? (
                    <amp-video
                      autoplay
                      loop
                      width="720"
                      height="1280"
                      // poster={item.}
                      layout="responsive"
                    >
                      <source src={item.source} type="video/mp4" />
                    </amp-video>
                  ) : null}
                </amp-story-grid-layer>
                <amp-story-grid-layer template="thirds">
                  <div grid-area="upper-third">
                    {settings?.position_title == "upper-third" && (
                      <h1
                        className={`${settings?.color_title || ""} ${
                          settings?.size_title || ""
                        }`}
                      >
                        {item?.title}
                      </h1>
                    )}
                    {settings?.position_description == "upper-third" && (
                      <p
                        className={`${settings?.color_description || ""} ${
                          settings?.size_description
                        }`}
                      >
                        {item.description}
                      </p>
                    )}
                  </div>
                  <div
                    grid-area="middle-third"
                    className={`${item?.settings?.template || ""} `}
                  >
                    {settings?.position_title == "middle-third" && (
                      <h1
                        className={`${settings?.color_title || ""} ${
                          settings?.size_title || ""
                        }`}
                      >
                        {item?.title}
                      </h1>
                    )}
                    {settings?.position_description == "middle-third" && (
                      <p
                        className={`${settings?.color_description || ""} ${
                          settings?.size_description
                        }`}
                      >
                        {item.description}
                      </p>
                    )}
                  </div>
                  <div grid-area="lower-third">
                    {settings?.position_title == "lower-third" && (
                      <h1
                        className={`${settings?.color_title || ""} ${
                          settings?.size_title || ""
                        }`}
                      >
                        {item?.title}
                      </h1>
                    )}
                    {settings?.position_description == "lower-third" && (
                      <p
                        className={`${settings?.color_description || ""} ${
                          settings?.size_description
                        }`}
                      >
                        {item.description}
                      </p>
                    )}
                    {item?.link && (
                      <a className="amp-link" href={`${item.link}`}>
                        Ver más
                      </a>
                    )}
                  </div>
                </amp-story-grid-layer>
              </amp-story-page>
            );
          })}
        </amp-story>
      </body>
    </html>
  );
}
