import {
  DateField,
  List,
  TextField,
  ReferenceField,
  Datagrid,
} from "react-admin";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";
import googleFilters from "./googleFilters";

export const GoogleStoryList = ({ ...props }) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<ActionsCreate label="Nueva Story"/>}
      filters={googleFilters}
      perPage={25}
      title=" "
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="title" label="Titulo" />
        <TextField source="slug" label="Slug" />
        <ReferenceField source="user_id" reference="users" link="show">
          <TextField source="username" />
        </ReferenceField>
        <DateField source="created_at" label="Fecha creación" />
        <DateField source="update_at" label="Última modificación" />
      </Datagrid>
    </List>
  );
};
