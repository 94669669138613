export const getCroppedImg = (imageSrc, pixelCrop, format) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d', { alpha: true });

            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height
            );

            canvas.toBlob((blob) => {
                if (!blob) {
                    return reject('Canvas is empty');
                }

                const file = new File([blob], 'croppedImage.' + format.split('/')[1], { 
                    type: format,
                });
                resolve(file);
            }, format); 
        };
        image.onerror = () => {
            reject('Image failed to load');
        };
    });
};