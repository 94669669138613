import { stringify } from "query-string";
import {
  httpClientBenefits,
  apiUrlBenefitsAdmin,
  sendAsFormData,
} from "../utils/utilsFetchBenefits";

const dataProviderApi = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sortBy: field,
      sortType: order,
      page: page,
      size: perPage,
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrlBenefitsAdmin}/${resource}?${stringify(query)}`;

    return httpClientBenefits(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  getOne: (resource, params) => {
    return httpClientBenefits(`${apiUrlBenefitsAdmin}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json,
      })
    );
  },
  update: (resource, params) => {
    if (resource === "benefit_assets") {
      return sendAsFormData("PUT", resource, params);
    }

    return httpClientBenefits(`${apiUrlBenefitsAdmin}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  create: (resource, params) => {
    if (resource === "benefit_assets") {
      return sendAsFormData("POST", resource, params);
    }
    return httpClientBenefits(`${apiUrlBenefitsAdmin}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource, params) =>
    httpClientBenefits(`${apiUrlBenefitsAdmin}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),
};
export default dataProviderApi;
