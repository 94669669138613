import * as React from 'react';
import {
    AutocompleteInput,
    DateInput,
    ReferenceInput,
    SearchInput,
} from 'react-admin';

const googleFilters = [
    <SearchInput source="search" alwaysOn/>,
    <DateInput
        source="created_at"
        label="Fecha creación"
    />,
    <ReferenceInput filterToQuery={searchText => ({ search: searchText })} source="user_id" reference="users">
        <AutocompleteInput optionText="username" />
    </ReferenceInput>,
];

export default googleFilters;
