import SvgIcon from "@material-ui/core/SvgIcon";

export const PubliclyIcon = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 11.4015V15.9762L0.914956 16.3633V12.8443L16.6452 17.4542V12.4924H19.9883C25.3372 12.4924 25.3372 5.31348 19.9883 5.31348H15.5543C15.5543 9.00849 15.5543 12.4924 15.5543 15.9762L0 11.4015ZM16.6452 11.5774V6.26362H19.9883C24.0704 6.26362 24.0704 11.5774 19.9883 11.5774H16.6452Z"
          fill="#000000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10.4868L10.5572 13.2317V12.3167L0.914956 9.7478V6.43988L15.5543 1.47801V4.39883H16.6452V0L0 5.87683V10.4868Z"
          fill="#000000"
        />
      </svg>
    </SvgIcon>
  );
};
