import * as React from "react";
import { Route } from "react-router-dom";
import CustomRecover from "../views/auth/CustomRecover";
import CustomReset from "../views/auth/CustomReset";
import PreviewStory from "../views/google_stories/preview-story";

export const PublicRoutes = {
  RECOVER: "recovery-password",
  RESET: "reset-password",
};
const customRoutes = [
  <Route exact path="/preview-story" component={PreviewStory} />,
  <Route
    exact
    path={`/${PublicRoutes.RECOVER}`}
    noLayout
    render={(props) => <CustomRecover {...props} />}
  />,
  <Route
    exact
    path={`/${PublicRoutes.RESET}`}
    noLayout
    render={(props) => <CustomReset {...props} />}
  />,
];
export default customRoutes;