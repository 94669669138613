import React, { useState, useEffect } from "react";

import { Box, makeStyles,TextField, Typography } from "@material-ui/core";
import { Button, useRecordContext } from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageDefault from "../../assets/img/note-default.png";
import { useField, useFormState } from "react-final-form";
import { useTranslate } from "ra-core";

const useStylesSelected = makeStyles({
  container: {
    "& img": {
      objectFit: "cover",
    },
    "& button:hover + img": {
      filter: "blur(3px) brightness(.9)",
    },
    display:"flex",
    flexDirection:"column"
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  deleteButton: {
    lineHeight: 1,
    color: "rgb(31, 58, 79)",
    background: "rgba(255, 255, 255, 0.8)",
    boxShadow: "1px 1px 3px 1px #0000002b",
    borderRadius: "50px",
    fontSize: "12px",
    minWidth: "28px",
    width: "28px",
    height: "28px",
    "& .MuiSvgIcon-root": {
      width: "18px",
      height: "18px",
    },
    "&:hover": {
      background: "rgba(255, 255, 255, 0.8)",
    },
  },
  btn: {
    color: "rgb(31, 58, 79)",
    background: "rgba(255, 255, 255, 0.8)",
    boxShadow: "1px 1px 3px 1px #0000002b",
  },
  btnAdd:{
    position: "absolute",
    left: "12px",
    top: "12px",
    zIndex: 2,
    color: "rgb(31, 58, 79)",
    background: "rgba(255, 255, 255, 0.8)",
    boxShadow: "1px 1px 3px 1px #0000002b",
  },
  helper:{
    maxWidth: "180px",
  }
});

export const ImageSelect = ({
  imgSize = { imgWidth: null, imgHeight: null },
  source,
  index,
  indexComponent,
  indexStep,
  scopedFormData,
  customSource = false,
  showLabel = true,
  helperText= null,
}) => {
  const { imgWidth, imgHeight } = imgSize;
  const record = useRecordContext();
  const { values } = useFormState();
  const { input: imagen } = useField(source);
  const [imageSelected, setImageSelected] = useState("");
  const classes = useStylesSelected();
  const [customEvent, setCustomEvent] = useState();
  const translate = useTranslate();
  
  useEffect(() => {
  
    if ( record != undefined && record != {}  && record[`${source}`] && !scopedFormData) {
      setImageSelected(record[`${source}`]);
    } else {
      if (scopedFormData && !customSource) {
        setImageSelected(scopedFormData?.image);
      } else {
        if (scopedFormData && customSource) {
          setImageSelected(scopedFormData[`${customSource}`]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, scopedFormData]);

  useEffect(() => {
    if (values[`${source}`]) {
      setImageSelected(values[`${source}`]);
    }
  }, [values[`${source}`]]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCallback = (event) => {
    const { editorId, image } = event.detail;
    if (
      editorId ===
      `selectedImage${typeof indexStep == "number" ? `-${indexStep}` : ""}${
        typeof indexComponent == "number" ? `-${indexComponent}` : ""
      }${typeof index == "number" ? `-${index}` : ""}`
    ) {
      setImageSelected(image.imageUrl);
      imagen.onChange(image.imageUrl);
    }
  };

  const handleClick = () => {
    window.addEventListener(`selected-image-imagepicker`, handleCallback);
    window.dispatchEvent(customEvent);
  };

  const handleDelete = () => {
    setImageSelected(null);
    imagen.onChange(undefined);
  };

  useEffect(() => {
    setCustomEvent(
      new CustomEvent("open-imagepicker", {
        detail: {
          editor: `selectedImage${
            typeof indexStep == "number" ? `-${indexStep}` : ""
          }${typeof indexComponent == "number" ? `-${indexComponent}` : ""}${
            typeof index == "number" ? `-${index}` : ""
          }`,
        },
        bubbles: false,
        cancelable: true,
        composed: false,
      })
    );
    return () => {
      setCustomEvent(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={classes.container} style={{ position: "relative" }}>
        {imageSelected && imageSelected !== "" ? (
          <Box
            style={{
              position: "absolute",
              top: "12px",
              padding: "0 12px",
              boxSizing: "border-box",
              zIndex: 2,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: imgWidth ?? "390px",
            }}
          >
            <Button
              onClick={handleClick}
              label={showLabel ? translate("components.update") : ""}
              className={!showLabel ? classes.deleteButton : classes.btn}
            >
              <AutorenewIcon />
            </Button>
            <Button onClick={handleDelete} className={classes.deleteButton}>
              <DeleteIcon />
            </Button>
          </Box>
        ) : (
          <Button
            onClick={handleClick}
            label={showLabel ? translate("components.add") : ""}
            className={`${classes.btnAdd} ${!showLabel ? classes.deleteButton : ""}`}
          >
            <IconContentAdd />
          </Button>
        )}
        <img
          style={{ width: imgWidth ?? "390px", height: imgHeight ?? "190px" }}
          src={imageSelected ? imageSelected : ImageDefault}
          alt=""
          className={classes.img}
        />
        {helperText && <Typography variant="caption" className={classes.helper}>{helperText}</Typography>}
      </div>
      <div className={classes.hidden}>
        <TextField
          id={"TextField-image"}
          label="Imagen"
          value={imageSelected}
         
        />
      </div>
    </>
  );
};
