import React, { useRef } from "react";
import { Button, CircularProgress } from "@material-ui/core";

const LoadingButton = React.forwardRef(
  ({ loading, children, ...props }, ref) => {
    if (loading) {
      return (
        <Button
          startIcon={
            <CircularProgress
              size={14}
              thickness={4}
              style={{ marginRight: "4px" }}
            />
          }
          {...props}
          ref={ref}
        >
          {children}
        </Button>
      );
    } else {
      return (
        <Button {...props} ref={ref}>
          {children}
        </Button>
      );
    }
  }
);

export default LoadingButton;
