
export const STATUS = [
  { id: "draft", name: "Borrador" },
  { id: "publish", name: "Publicada" },
  { id: "scheduled", name: "Programada" },
  { id: "deleted", name: "Borrada" },
];

export const POSITIONS = [
  {id:  "upper-third", name:"Arriba"},
  {id: "middle-third" , name:"Medio"},
  {id: "lower-third", name:"Abajo"}
];

export const SIZES = [
  {id: "small" ,name:"Chica (24px)"},
  {id: "medium", name:"Mediana (32px)"},
  {id: "large", name:"Grande (40px)"},
  {id: "extral", name:"Extra Grande (48px)"}
];

export const COLORS = [
  { name: "blue", title:"Azul", value: '#1f3a4f'},
  { name: "lightBlue", title:"Celeste", value: '#14a9d4'},
  { name: "lightGreen",title:"Verde Claro" ,value: '#6dac97'},
  { name: "green", title:"Verde", value: '#98bf17'},
  { name: "yellow", title:"Amarillo",value: '#fbbc04'},
  { name: "red", title:"Rojo",value: '#b9000b'},
  { name: "white", title:"Blanco",value: '#ffffff'},
  { name: "ligthgrey", title:"Claro", value: '#dfdfdf'},
  { name: "grey", title:"Gris",value: '#6f6f6f'},
  { name: "dark", title:"Oscuro",value: '#404040'},
  { name: "black", title:"Negro", value: '#1b1b1b'},
  { name: "bronce", title:"Bronce",value: '#e4d7b6'},
  { name: "orangeDark", title:"Naranja oscuro", value: '#c85529'},
  { name: "orange", title:"Naranja", value: '#ff7a00'},
];