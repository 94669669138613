import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactMarkdown from "react-markdown";

export const HistoryIA = () => {
  const [list, setList] = useState([]);
  const [responses, setResponses] = useState([]);

  const handleEvento = (detail) => {
    const value = detail.value;
    if (
      !responses.includes(
        (i) => parseInt(i?.id) === parseInt(detail?.value?.id)
      )
    ) {
      setResponses((prev) => [...prev, value]);
    }
  };
  useEffect(() => {
    let local = localStorage.getItem("questions-ia");
    if (local) {
      let responses = JSON.parse(local);
      setResponses(responses);
    }
    const eventoListener = (evento) => {
      handleEvento(evento.detail);
    };
    document.addEventListener("update-questions-ia", eventoListener);

    return () => {
      document.removeEventListener("update-questions-ia", eventoListener);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (responses) {
      localStorage.setItem("questions-ia", JSON.stringify(responses));
      setList(responses?.sort((a, b) => b.id - a.id));
    }
  }, [responses]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    let new_resp = responses.filter(
      (i) => parseInt(i?.id) !== parseInt(item?.id)
    );
    setResponses(new_resp);
  };

  if (list?.length === 0) return null;
  return (
    <>
      <Typography variant="h6">Consultas anterioress</Typography>
      <Box width="100%">
        {list?.map((item, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  <strong>{item?.query}</strong>{" "}
                </Typography>
                <Box>
                  <IconButton
                    onClick={(e) => handleDelete(e, item)}
                    color="secondary"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ display: "block" }}>
              <ReactMarkdown>{item?.response}</ReactMarkdown>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
};
