import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  Labeled,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  SelectInput,
  DateInput,
} from "react-admin";
import { ImageSelect } from "../../components/pickers/ImageSelectFromSource";
import ImagePicker from "../../components/pickers/ImagePicker";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Fragment, useState } from "react";
import { CustomAddButton } from "../../components/ArrayInputButton/CustomAddButton";
import { CustomDeleteButton } from "../../components/ArrayInputButton/CustomDeleteButton";
import { useEditContext, useRecordContext, useTranslate } from "ra-core";
import ColorPicker from "../../components/pickers/ColorPicker";
import { SIZES, POSITIONS, STATUS, COLORS } from "./constants";
import { useEffect } from "react";
import { convertDateToString } from "../../utils/utilsDateFormat";


const useStyles = makeStyles({
  array: {
    alignItems: "center",
  },
  input: {
    marginTop: 0,
  },
});

const ImageSelection = () => {
  return (
    <Box display="flex">
      <Labeled label="Imagen miniatura *">
        <ImageSelect
          source={"image"}
          imgSize={{ imgWidth: "170px", imgHeight: "170px" }}
          index={1}
          indexComponent={1}
          validate={[required()]}
          showLabel={false}
        />
      </Labeled>
    </Box>
  );
};
Array.prototype.insert = function (index, ...items) {
  this.splice(index, 0, ...items);
};

const Slides = ({ source, record }) => {
  const translate = useTranslate();
  const classes = useStyles();
  
  return (
    <Fragment>
      <ArrayInput source={source} label=" ">
        <SimpleFormIterator
          getItemLabel={(index) => ` `}
          addButton={
            <CustomAddButton
              label={translate("resources.google_stories.add_slide")}
            />
          }
          removeButton={<CustomDeleteButton className={classes.array} />}
          TransitionProps={{ enter: false, exit: false }}
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData, id }) => {
              return (
                <Box display="flex" flexDirection="column" p={1}>
                  <Box display="flex">
                    <ImageSelect
                      source={getSource("source")}
                      index={Number(id?.split("[")[1]?.split("]")[0])}
                      indexComponent={2}
                      imgSize={{ imgWidth: "180px", imgHeight: "320px" }}
                      scopedFormData={scopedFormData}
                      customSource={"source"}
                      validate={required()}
                      showLabel={false}
                      helperText={translate("resources.google_stories.aspect_image")}
                    />
                    <Box display="flex" flexDirection="column" paddingLeft={1}>
                      <Box display="flex" flexDirection="column">
                        <TextInput
                          source={getSource("title")}
                          label="Titulo"
                          className={classes.input}
                        />
                        <Box display="flex" alignItems="end">
                          <Box mr="1em">
                            <SelectInput
                              source={getSource("position_title")}
                              choices={POSITIONS}
                              label="Posición"
                              style={{ marginTop: "13px" }}
                            />
                          </Box>
                          <Box mr="1em">
                            <SelectInput
                              source={getSource("size_title")}
                              choices={SIZES}
                              label="Tamaño tipografía"
                              style={{ marginTop: "13px" }}
                            />
                          </Box>
                          <Box mr="1em">
                            <ColorPicker
                              source={getSource("color_title")}
                              label="Color letra"
                              colors={COLORS}
                              className={classes.input}
                            />
                          </Box>
                          <Box>
                            <ColorPicker
                              source={getSource("background_title")}
                              label="Color fondo"
                              colors={COLORS}
                              record={scopedFormData}
                              className={classes.input}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <TextInput
                          source={getSource("description")}
                          label="Descripción"
                          className={classes.input}
                        />
                        <Box display="flex" alignItems="end">
                          <Box mr="1em">
                            <SelectInput
                              source={getSource("position_description")}
                              choices={POSITIONS}
                              label="Posición"
                              style={{ marginTop: "13px" }}
                            />
                          </Box>
                          <Box mr="1em">
                            <SelectInput
                              source={getSource("size_description")}
                              choices={SIZES}
                              label="Tamaño tipografía"
                              style={{ marginTop: "13px" }}
                            />
                          </Box>
                          <Box mr="1em">
                            <ColorPicker
                              source={getSource("color_description")}
                              label="Color letra"
                              colors={COLORS}
                              className={classes.input}
                            />
                          </Box>
                          <Box>
                            <ColorPicker
                              source={getSource("background_description")}
                              label="Color fondo"
                              colors={COLORS}
                              className={classes.input}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <TextInput
                          source={getSource("link")}
                          label="Link"
                          className={classes.input}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Fragment>
  );
};

const SimpleFields = ({ ...props }) => {
  const record = useRecordContext();
  const [state, setState] = useState();
  const { save } = useEditContext();
  const translate = (values) => {
    let res = values;
    res?.google_slides?.forEach((element) => {
      Object.assign(element, element.settings);
    });
    return res;
  };
  useEffect(() => {
    if (record) {
      setState(translate(record));
    }
  }, [record]);

  const transform = (values) => {
    let res = { ...values };
    res?.google_slides.forEach((element) => {
      let settings = {
        //color
        color_title: element.color_title,
        color_description: element.color_description,
        background_title: element.background_title,
        background_description: element.background_description,
        //position
        position_title: element.position_title,
        position_description: element.position_description,
        //size
        size_title: element.size_title,
        size_description: element.size_description,
      };
      element.settings = settings;
    });
    return res;
  };

  const handleSave = (values) => {
    save(transform(values));
  };
  if (!state) return null;
  return (
    <SimpleForm save={handleSave} record={state} tr>
      <Typography variant="h6">History</Typography>
      <TextInput source="title" label="Titulo" validate={[required()]} />
      <TextInput
        source="description"
        label="Descripción"
        validate={[required()]}
      />
      <DateInput
        source="date_publish"
        label="Fecha de publicación"
        defaultValue={convertDateToString(new Date())}
        validate={[required()]}
      />
      <SelectInput
        source="state"
        label="Estado"
        choices={STATUS}
        validate={[required()]}
      />
      <ImageSelection />
      <Box margin="1em 0 .5em 0">
        <Typography variant="h6">Slides</Typography>
      </Box>
      <Slides source={"google_slides"} record={state} />
      <ImagePicker permissions={props.permissions} />
    </SimpleForm>
  );
};

export const GoogleStoryEdit = ({ ...props }) => {
  return (
    <Edit title=" " {...props}>
      <SimpleFields {...props} />
    </Edit>
  );
};
