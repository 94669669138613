import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useTranslate } from "ra-core";
import { useState } from "react";
import {
  Create,
  fetchEnd,
  fetchStart,
  RadioButtonGroupInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
} from "react-admin";
import { useFormState } from "react-final-form";
import { useDispatch } from "react-redux";
import medio from "Static/data/medio.json";
import { ChatIA } from "../../components/IA/ChatIA";
import { HistoryIA } from "../../components/IA/HistoryIA";
import { apiUrl, httpClient } from "../../utils/utilsFetch";

const useStyles = makeStyles({
  label: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "1.6",
    letterSpacing: "0.0075em",
    color: "rgba(0, 0, 0, 0.87)!important",
    transform: "none",
  },
});

const FormQuestion = ({ response, setResponse, loading, sendForm }) => {
  const { values } = useFormState();
  const translate = useTranslate();
  const handleReset = () => {
    setResponse(false);
  };
  const [open, setOpen] = useState(false);
  return (
    <Box>
      {medio?.enabled_question_ia ? (
        <ChatIA handleResponse={() => setResponse(true)} />
      ) : null}
      {response ? (
        <Box>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{
              textDecoration: "underline",
              margin: "16px 0",
              cursor: "pointer",
              opacity: 0.9,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={() => setOpen(true)}
          >
            {translate("components.contact_form_question")}
          </Typography>
        </Box>
      ) : null}
      {open || !medio?.enabled_question_ia ? (
        <Box>
          <Typography variant="h6">
            {translate("components.contact_form_title")}
          </Typography>
          <Typography style={{ marginBottom: ".5em" }}>
            {translate("components.create_ticket_text")}
          </Typography>
          <TextInput
            style={{ width: "100%" }}
            label={translate("components.description")}
            source="description"
            multiline
            minRows={5}
            validate={requiredValidate}
          />
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Box>
              <SaveButton
                label={translate("ra.action.send")}
                disabled={loading}
                icon={
                  loading ? <CircularProgress size={18} thickness={2} /> : <></>
                }
                handleSubmitWithRedirect={() => {
                  sendForm(values);
                  handleReset();
                }}
              />
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

const FormBugg = ({ loading, sendForm }) => {
  const { values } = useFormState();
  const translate = useTranslate();
  const prioridad = [
    { id: "urgente", name: `${translate("components.priorities.urgent")}` },
    { id: "alta", name: `${translate("components.priorities.high")}` },
    { id: "media", name: `${translate("components.priorities.medium")}` },
    { id: "baja", name: `${translate("components.priorities.low")}` },
  ];
  return (
    <Box>
      <Typography variant="h6">
        {translate("components.contact_form_report")}
      </Typography>
      <Typography style={{ marginBottom: ".5em" }}>
        {translate("components.contact_form_describe_problem")}
      </Typography>
      <Typography tyle={{ marginBottom: ".5em" }}>
        {translate("components.create_ticket_text")}
      </Typography>
      <TextInput
        style={{ width: "100%" }}
        label={translate("components.description")}
        source="description"
        multiline
        minRows={5}
        validate={requiredValidate}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6">
            {translate("components.suggested_priority")}
          </Typography>
          <RadioButtonGroupInput
            choices={prioridad}
            label=""
            source="priority"
            validate={requiredValidate}
          />
        </Box>
        <Box>
          <SaveButton
            label={translate("ra.action.send")}
            disabled={loading}
            icon={
              loading ? <CircularProgress size={18} thickness={2} /> : <></>
            }
            handleSubmitWithRedirect={() => sendForm(values)}
          />
        </Box>
      </Box>
    </Box>
  );
};

const FormSuggestion = ({ loading, sendForm }) => {
  const { values } = useFormState();
  const translate = useTranslate();
  return (
    <Box>
      <Typography variant="h6">
        {translate("components.suggested_functionality")}
      </Typography>
      <Typography style={{ marginBottom: ".5em" }}>
        {translate("components.create_ticket_text")}
      </Typography>
      <TextInput
        style={{ width: "100%" }}
        label={translate("components.description")}
        source="description"
        multiline
        minRows={5}
        validate={requiredValidate}
      />
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Box>
          <SaveButton
            label={translate("ra.action.send")}
            disabled={loading}
            icon={
              loading ? <CircularProgress size={18} thickness={2} /> : <></>
            }
            handleSubmitWithRedirect={() => sendForm(values)}
          />
        </Box>
      </Box>
    </Box>
  );
};

const Form = ({ loading, sendForm }) => {
  const classes = useStyles();
  const { values } = useFormState();
  const [response, setResponse] = useState();
  const translate = useTranslate();
  const tipos = [
    { id: "pregunta", name: `${translate("components.has_question")}` },
    { id: "problema", name: `${translate("components.problem")}` },
    { id: "sugerencia", name: `${translate("components.suggested")}` },
  ];

  return (
    <>
      <Typography variant="h6" style={{ fontSize: "26px" }}>
        {translate("components.support")}
      </Typography>
      <Box display="flex" style={{ gap: "20px" }}>
        <Box width="100%" maxWidth="610px">
          <RadioButtonGroupInput
            source="type"
            row={false}
            choices={tipos}
            label={"components.support_title"}
            classes={classes}
          />
          {values?.type ? (
            <Box display="flex" flexDirection="column" width="100%">
              {values?.type === "pregunta" ? (
                <FormQuestion
                  response={response}
                  setResponse={setResponse}
                  loading={loading}
                  sendForm={sendForm}
                />
              ) : null}
              {values?.type === "problema" ? (
                <FormBugg loading={loading} sendForm={sendForm} />
              ) : null}
              {values?.type === "sugerencia" ? (
                <FormSuggestion loading={loading} sendForm={sendForm} />
              ) : null}
            </Box>
          ) : null}
        </Box>
        <Box justifySelf="flex-end" marginLeft="auto" maxWidth="500px">
          {values?.type == "pregunta" && medio?.enabled_question_ia ? (
            <HistoryIA />
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export const ContactFormCreate = ({ ...props }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();

  const sendForm = async (data) => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    if (!data?.priority) {
      data.priority = "baja";
    }
    httpClient(apiUrl + "/technical-assistance", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) {
          notify("ra.notification.ticket_created");
          refresh();
        } else {
          notify("ra.notification.http_error", { type: "warning" });
        }
      })
      .catch((e) => {
        notify("ra.notification.http_error", { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  return (
    <Create {...props} title=" ">
      <SimpleForm toolbar={null}>
        <Form loading={loading} sendForm={sendForm} />
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];
