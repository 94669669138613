import { TextField, Typography, Box } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useNotify } from "ra-core";
import { apiUrl, httpClient } from "../../utils/utilsFetch";
import ReactMarkdown from "react-markdown";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import { useTranslate } from "ra-core";

export const ChatIA = ({ handleResponse }) => {
  const [state, setState] = useState("");
  const [currentResponse, setCurrentResponse] = useState();

  const [loading, setLoading] = useState(false);
  const notification = useNotify();
  const ref = useRef();
  const [last_id, setLastId] = useState(0);
  const translate = useTranslate();

  useEffect(() => {
    let local = localStorage.getItem("questions-ia");
    if (local) {
      let responses = JSON.parse(local);
      const sorted = responses.sort((a, b) => b.id - a.id);
      setLastId(parseInt(sorted[0]?.id) || 0);
    }
  }, []);

  const updateResponses = (value) => {
    document.dispatchEvent(
      new CustomEvent("update-questions-ia", {
        detail: { value: value ? value : currentResponse },
      })
    );
  };

  useEffect(() => {
    if (state && currentResponse) {
      updateResponses();
      setCurrentResponse(null);
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAccept = async () => {
    if (!loading) {
      setLoading(true);
      if (currentResponse) {
        updateResponses();
        setCurrentResponse(null);
      }
      try {
        let response = await httpClient(apiUrl + "/artic-ia/question", {
          method: "POST",
          body: JSON.stringify({ query: state }),
        });
        const json = response?.json || null;
        if (json?.response && json?.success === true) {
          const new_id = last_id + 1;
          setLastId(new_id);
          const new_obj = {
            query: state,
            response: json.response,
            id: new_id,
          };
          setCurrentResponse(new_obj);
          updateResponses(new_obj);
          if (handleResponse) {
            handleResponse();
          }
          setLoading(false);
          setState("");
        } else {
          setLoading(false);
          setCurrentResponse(null);
          if (handleResponse) {
            handleResponse();
          }
          notification(
            json?.response ||
            translate("ra.notification.error_fetch")
             ,
            {
              type: "error",
            }
          );
        }
      } catch ($e) {
        console.log("catch", $e);
        setLoading(false);
        notification(
          translate("ra.notification.error_fetch"),
          {
            type: "error",
          }
        );
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      ref?.current?.click();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      style={{ gap: "20px", marginBottom: "30px" }}
    >
      <Typography variant="h6">{translate("components.chat_ia_question")}</Typography>
      <TextField
        value={state}
        label=""
        placeholder={translate("components.chat_ia_question_helper")}
        multiline
        minRows={5}
        fullWidth
        onKeyDown={handleKeyDown}
        onChange={(e) => setState(e.target.value)}
        variant="filled"
      />
      <ButtonLoader
        loading={loading}
        onClick={() => handleAccept()}
        variant="contained"
        color="primary"
        disabled={loading || state?.length === 0}
        style={{ width: "200px", alignSelf: "flex-end" }}
        ref={ref}
      >
        {translate("components.chat_ia_button")}
      </ButtonLoader>

      {currentResponse ? (
        <>
          <Box>
            <Box>
              <Typography variant="subtitle1">
                <strong>{currentResponse?.query}</strong>
              </Typography>
            </Box>
            <ReactMarkdown>{currentResponse?.response}</ReactMarkdown>
          </Box>
        </>
      ) : null}
    </Box>
  );
};
