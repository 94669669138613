import {
    Create, SimpleForm, ImageInput, ImageField, TextInput, useNotify, required, SaveButton, DeleteButton
} from "react-admin";
import { FormWithRedirect, useRedirect, useTranslate } from 'ra-core';
import { bytesToSize } from "../../../utils/validations";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
const maxSizePhoto = 2097152;


const useStyles = makeStyles(
    theme => ({
        toolbar: {
            backgroundColor:
                theme.palette.type === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
        },
        desktopToolbar: {
            marginTop: theme.spacing(2),
        },
        mobileToolbar: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '16px',
            width: '100%',
            boxSizing: 'border-box',
            flexShrink: 0,
            zIndex: 2,
        },
        defaultToolbar: {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
        },
        spacer: {
            [theme.breakpoints.down('xs')]: {
                height: '5em',
            },
        },
        mediaType: {
            display: "none"
        }
    }),
    { name: 'RaToolbar' }
);

const validatePhoto = (value, allValues) => {
    if (allValues) {
        allValues?.picture?.forEach(img => {
            let size = img?.rawFile?.size || 0;
            if (size > 0 && size > maxSizePhoto) {
                return {
                    message: 'resources.media.image_maxFilesize',
                    messageArgs: { maxSize: bytesToSize(maxSizePhoto) }
                };
            }
        });
        return undefined;
    }
    return undefined;
}

const ImageComponent = () => {
    const translate = useTranslate();
    const notification = useNotify();

    const onDrop = (newFiles, rejectedFiles, event) => {
        if (rejectedFiles?.length > 0) {
            notification('resources.media.image_maxFilesize', { type: 'error', messageArgs: { maxSize: bytesToSize(maxSizePhoto) } });
        }
    };

    return (
        <ImageInput
            source="file"
            label="Imagen"
            accept="image/*"
            maxSize={maxSizePhoto}
            validate={[validatePhoto, required()]}
            options={{ onDrop }}
            multiple
            helperText={translate('resources.media.image_maxFilesize', { maxSize: bytesToSize(maxSizePhoto) })}
        >
            <ImageField source="picture_prev" title="Upload Preview" />
        </ImageInput>
    )
}

export const BenefitAssetsCreate = (props) => {
    const classes = useStyles(props);
    const transform = (values) => {
        let result = { ...values };
        result.company = apiCOD;
        return result;
    }

    const notify = useNotify();
    const redirect = useRedirect();

    // ========================================================================
    // Prevenimos el callback automatico de RA para poder elegir que hacer
    // ========================================================================
    const successOverride = ({ data }) => {

        const response = data.response;

        // Comportamiento default del OnSuccess
        if (!props.innote) {
            redirect('list', '/benefit_assets');
            notify('ra.notification.created', 'info');
        } else {
            if (response) {
                props.successcallback(response);
                notify('ra.notification.created', 'info');
            } else {
                // Error general
                notify('ra.message.error', 'error');
            }
        }
    }

    const failureOverride = (error) => {
        if (error) {
            if (error.message === 'ra.notification.data_provider_error') {
                // Considera el ra.notification.data_provider_error como un error de falla de multiples archivos
                notify('resources.media.maxFilesizeMultiple', 'error')
            } else {
                notify(error.message, 'error');
            }
        } else {
            // Error general
            notify('ra.message.error', 'error');
        }
    }

    return (
        <Create onFailure={failureOverride} onSuccess={successOverride} mutationMode="pessimistic" undoable={false} transform={transform}  {...props}>
            <FormWithRedirect
                {...props}
                render={formProps => (
                    <form>
                        <Box display="flex" flexDirection="column" maxWidth="50%" m="1em">
                            <ImageComponent />
                            <TextInput source="alt" />
                        </Box>
                        <Toolbar className={classnames(
                            classes.toolbar
                        )}
                            role="toolbar">
                            <Box display="flex" justifyContent="flex-end" width="100%">
                                <SaveButton
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                                <DeleteButton record={formProps.record} />
                            </Box>
                        </Toolbar>
                    </form>
                )} />
        </Create>
    )
}